import React from 'react'
import './Footer.css'
import EmailListForm from './EmailListForm'

export default ({ content }) => (
  <div>
    <footer className="footer">
      <div className="container taCenter">
        <EmailListForm />
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </footer>
  </div>
)

import React, { useState } from 'react'
import './EmailListForm.css'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const EmailListForm = () => {
  const [email, setEmail] = useState('')

  const handleSubmit = e => {
    e.preventDefault()

    addToMailchimp(email)
      .then(data => {
        alert(data.result)
      })
      .catch(error => {
        // Errors in here are client side
        // Mailchimp always returns a 200
      })
  }

  const handleEmailChange = event => {
    setEmail(event.currentTarget.value)
  }

  return (
    <div>
      <p  style={{marginTop: 'auto'}}>Bądź na bieżąco</p>
      <form onSubmit={handleSubmit}>
        <div class="mailchimp" style={{}}>
          <input
              style={{}}
              class="email"
            placeholder="Wpisz swój e-mail"
            name="email"
            type="text"
            onChange={handleEmailChange}
          />
          <button
            class="subscribe"
            type="submit"
            style={{}}
          >
            Subskrybuj
          </button>
        </div>
      </form>
    </div>
  )
}

export default EmailListForm
